
































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Article, listArticles } from '@/admin/articles/articles'
import Loading from '@/app/Loading.vue'

@Component({
  components: {
    Loading
  }
})
export default class ArticlesList extends Vue {
  published: boolean = false

  articles: Array<Article> | null = null

  @Watch('published')
  async fetchArticles() {
    this.articles = null
    this.articles = await listArticles(this.$store.state.user.authToken, this.published)
  }

  mounted() {
    this.fetchArticles()
  }
}
