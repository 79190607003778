



















































import { Component, Vue, Watch } from 'vue-property-decorator'
import Render from '@/admin/articles/Render.vue'
import { ArticleSaveEventType, getArticle, saveArticle } from '@/admin/articles/articles'
import Loading from '@/app/Loading.vue'

@Component({
  components: {
    Loading,
    Render
  }
})
export default class Edit extends Vue {
  title: string = ''
  slug: string = ''
  article: string = ''
  publish: boolean = false
  publishDateStr: string = ''
  showPreview: boolean = true
  dirty: boolean = false
  slugLocked: boolean = false
  loading: boolean = false

  get preventSave(): boolean {
    return !this.dirty || this.title === '' || this.slug === '' || this.article === '' || (this.publish && !this.publishDate)
  }

  get publishDate(): Date | undefined {
    const seconds = Date.parse(this.publishDateStr)
    if (seconds) {
      return new Date(seconds)
    }
  }

  @Watch('title')
  flagDirtyTitle() {
    this.dirty = true
  }

  @Watch('slug')
  flagDirtySlug() {
    this.dirty = true
  }

  @Watch('publishDate')
  flagDirtyPubDate() {
    this.dirty = true
  }

  @Watch('article')
  flagDirtyArticle() {
    this.dirty = true
  }

  mounted() {
    this.routeParamsChanged()
  }

  @Watch('$route')
  async routeParamsChanged() {
    const slug = this.$route.params.slug
    if (!slug) {
      return
    }
    this.loading = true
    const article = await getArticle(this.$store.state.user.authToken, slug)
    this.slug = slug
    this.slugLocked = true
    this.title = article.title
    this.article = article.content
    if (article.publishDate) {
      this.publish = true
      this.publishDateStr = article.publishDate as any as string
    }
    this.loading = false
  }

  async save() {
    this.loading = true
    this.dirty = false
    this.slugLocked = true
    const res = await saveArticle(this.$store.state.user.authToken, {
      slug: this.slug,
      title: this.title,
      content: this.article,
      publishDate: this.publishDate
    })
    if (res.eventType === ArticleSaveEventType.CREATED) {
      console.log(`new article created @ ${res.location}`)
    } else {
      console.log(`article updated`)
    }
    this.loading = false
  }
}
