





























import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
  }
})
export default class SignIn extends Vue {
  get isSignedIn(): boolean {
    return this.$store.state.user.signedIn && this.$store.state.user.self
  }

  get name(): string | null {
    if (this.$store.state.user.self) {
      return this.$store.state.user.self.name
    } else {
      return null
    }
  }
}
