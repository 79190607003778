




















import { Vue, Component, Watch } from 'vue-property-decorator'
import { readQuery, SearchResult } from '@/search/search'
import Loading from '@/app/Loading.vue'
import { SearchStore } from '@/search/SearchStore'

@Component({
  components: {
    Loading
  }
})
export default class Search extends Vue {
  get isLoading(): boolean {
    return this.searchResults == null
  }

  get resultsPresent(): boolean {
    return this.searchResults != null && this.searchResults.length > 0
  }

  get searchResults(): Array<SearchResult> | null {
    return this.$store.state.search.searchResults
  }

  mounted() {
    this.queryUpdated()
  }

  destroyed() {
    this.$store.dispatch(SearchStore.ACTION_CLEAR_SEARCH_RESULTS)
  }

  @Watch('$route')
  queryUpdated() {
    this.$store.dispatch(SearchStore.ACTION_DO_SEARCH, readQuery(this.$route))
  }
}
