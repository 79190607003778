






















import { Vue, Component } from 'vue-property-decorator'
import MainNav from './navigation/MainNav.vue'
import { AppStore } from '@/app/AppStore'
import TrackingConsent from '@/legal/TrackingConsent.vue'
import { UserStore } from '@/user/UserStore'

@Component({
  components: {
    MainNav,
    TrackingConsent
  }
})
export default class App extends Vue {
  created() {
    this.$store.dispatch(UserStore.ACTION_INITIALIZE)
  }

  get hasRemoteError(): boolean {
    return this.$store.state.app.errorToAck != null
  }

  set hasRemoteError(error) {
    if (!error) {
      this.$store.dispatch(AppStore.ACTION_ACK_REMOTE_ERROR)
    }
  }
}
