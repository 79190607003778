













import { Component, Vue, Watch } from 'vue-property-decorator'
import ArticleZero from './ArticleZero.vue'
import ArticleOne from './ArticleOne.vue'
import ArticleTwo from './ArticleTwo.vue'
import ArticleThree from './ArticleThree.vue'

@Component({
  components: { ArticleZero, ArticleOne, ArticleTwo, ArticleThree }
})
export default class Article extends Vue {
  articleId: number | null = null

  mounted() {
    this.routeUpdated()
  }

  @Watch('$route')
  routeUpdated() {
    try {
      this.articleId = parseInt(this.$route.params.id)
    } catch {
      // don't care
    }
  }
}
