
















import { Component, Vue, Watch } from 'vue-property-decorator'
import Cookies from 'js-cookie'
import startAnalytics from '@/legal/analytics'

const consentCookie = 'okToTrack'
const okToTrackValue = 'yes'
const doNotTrackValue = 'no'

@Component
export default class TrackingConsent extends Vue {
  gatherConsent: boolean = true

  created() {
    this.loadInitialState()
  }

  @Watch('$route')
  routeUpdated() {
    this.loadInitialState()
  }

  loadInitialState() {
    if (this.$route.query.skipTracking || isBot()) {
      this.gatherConsent = false
      return
    }
    const okToTrack = Cookies.get(consentCookie)
    if (okToTrack) {
      if (okToTrack === okToTrackValue) {
        this.gatherConsent = false
        startAnalytics()
      } else {
        this.gatherConsent = false
      }
    }
  }

  doNotTrack() {
    this.gatherConsent = false
    Cookies.set(consentCookie, doNotTrackValue)
  }

  consentGathered() {
    this.gatherConsent = false
    Cookies.set(consentCookie, okToTrackValue)
    startAnalytics()
  }
}

function isBot():boolean {
  return /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent)
}
