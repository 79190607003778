



























import SignIn from '@/user/SignIn.vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { readQuery } from '@/search/search'
import { SEARCH_ROUTE_NAME } from '@/navigation/router'
import { routeChanged } from '@/legal/analytics'

@Component({
  components: {
    SignIn
  }
})
export default class MainNav extends Vue {
  query: string = ''

  get disableSearch(): boolean {
    return this.query.trim() === '' || this.query === this.$store.state.search.query
  }

  get isAdmin(): boolean {
    return this.hasArticleAssetRole
  }

  get hasArticleAssetRole(): boolean {
    return this.hasRole('article_asset_publish')
  }

  get hasArticleEditRole(): boolean {
    return this.hasRole('article_publish')
  }

  hasRole(roleName: string): boolean {
    return this.$store.state.user && this.$store.state.user.self && this.$store.state.user.self.roles && this.$store.state.user.self.roles.includes(roleName)
  }

  mounted() {
    this.query = readQuery(this.$route)
  }

  executeSearch() {
    this.$router.push({
      name: SEARCH_ROUTE_NAME,
      query: {
        query: this.query
      }
    })
  }

  @Watch('$route')
  routeUpdated(r: any) {
    routeChanged({ 'page_path': r.path })
  }
}
