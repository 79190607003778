


























import { Component, Vue } from 'vue-property-decorator'
import { uploadAsset } from '@/admin/articles/assets'

@Component({})
export default class AssetUpload extends Vue {
  asset: File | null = null
  assetPath: string = ''
  uploading: boolean = false
  uploadSize: number = 1
  uploadProgress: number = 0
  newAssetUrl: string | null = null

  get hasFile(): boolean {
    return this.asset !== null
  }

  get disableUpload(): boolean {
    return !this.hasFile || this.assetPath === ''
  }

  handleUploadProgress(progressEvent: ProgressEvent) {
    this.uploadSize = progressEvent.total
    this.uploadProgress = progressEvent.loaded
  }

  completeUpload() {
    this.uploading = false
    this.asset = null
    this.assetPath = ''
    this.uploadSize = 1
    this.uploadProgress = 0
    this.newAssetUrl = null
  }

  uploadFinished(assetURL: string) {
    this.newAssetUrl = assetURL
  }

  uploadAsset() {
    if (!this.asset) {
      throw Error('asset not set on upload')
    }
    this.uploading = true
    uploadAsset(this.$store.state.user.authToken, this.asset, this.assetPath, this.uploadFinished, this.handleUploadProgress)
  }
}
