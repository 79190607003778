
























import { Component, Vue } from 'vue-property-decorator'
import { AssetListDto, listAssets } from '@/admin/articles/assets'
import Loading from '@/app/Loading.vue'

@Component({
  components: {
    Loading
  }
})
export default class AssetsList extends Vue {
  assets: Array<AssetListDto> | null = null

  async mounted() {
    this.assets = await listAssets(this.$store.state.user.authToken)
  }
}
