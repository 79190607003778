







// @ts-ignore
import GoogleLogin from 'vue-google-login'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    GoogleLogin
  }
})
export default class SignIn extends Vue {
  get isReady(): boolean {
    return this.$store.state.user.isReady
  }

  get isSignedIn(): boolean {
    return this.$store.state.user.signedIn
  }

  googleLoginParams = {
    client_id: process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID
  }
}
